import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'

const routes = [
	{
		path: '/schedule',
		name: 'WorkSchedule',
		component: () => import('@/views/WorkSchedule.vue'),
		meta: {
			layout: 'MainLayout',
		},
	},
	{
		path: '/admin',
		name: 'Dashboard',
		component: () => import('@/views/TheDashboard.vue'),
		meta: {
			layout: 'AdminLayout',
		},
		beforeEnter: (to, from, next) => {
			isAuthenticated(to, from, next)
		},
	},
	{
		path: '/admin/users/edit/:id',
		name: 'EditUser',
		component: () => import('@/views/EditUser'),
		meta: {
			layout: 'AdminLayout',
		},
		beforeEnter: (to, from, next) => {
			isAuthenticated(to, from, next)
		},
	},
	{
		path: '/admin/users',
		name: 'Users',
		component: () => import('@/views/TheUsers.vue'),
		meta: {
			layout: 'AdminLayout',
		},
		beforeEnter: (to, from, next) => {
			isAuthenticated(to, from, next)
		},
	},
	{
		path: '/admin/filters',
		name: 'Filters',
		component: () => import('@/views/TheFilters.vue'),
		meta: {
			layout: 'AdminLayout',
		},
		beforeEnter: (to, from, next) => {
			isAuthenticated(to, from, next)
		},
	},
	{
		path: '/admin/comings',
		name: 'Сomings',
		component: () => import('@/views/TheСomings.vue'),
		meta: {
			layout: 'AdminLayout',
		},
		beforeEnter: (to, from, next) => {
			isAuthenticated(to, from, next)
		},
	},
	{
		path: '/admin/mailing',
		name: 'Mailing',
		component: () => import('@/views/TheMailing.vue'),
		meta: {
			layout: 'AdminLayout',
		},
		beforeEnter: (to, from, next) => {
			isAuthenticated(to, from, next)
		},
	},
	{
		path: '/admin/chat/:id',
		name: 'Chat',
		component: () => import('@/views/TheChat.vue'),
		meta: {
			layout: 'AdminLayout',
		},
		beforeEnter: (to, from, next) => {
			isAuthenticated(to, from, next)
		},
	},
	{
		path: '/admin/login',
		name: 'LoginUser',
		component: () => import('@/views/TheLogin.vue'),
		meta: {
			layout: 'LoginLayout',
		},
	},
]

const router = createRouter({
	routes,
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior() {
		return { top: 0 }
	},
})

const isAuthenticated = (to, from, next) => {
	if (localStorage.getItem('token')) {
		next()
	} else {
		router.push({
			name: 'LoginUser',
		})
	}
}

export default router
