<template>
	<router-view></router-view>
</template>

<!-- <script setup>
import MainLayout from '@/layouts/MainLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const layout = computed(() => {
  return route.meta.layout || MainLayout
})
console.log(route.meta.layout)
</script> -->

<!-- <script>
import MainLayout from '@/layouts/MainLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || 'DefaultLayout'
    },
  },

  components: {
    MainLayout,
    AdminLayout,
  },
}
</script> -->

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
	padding: 0;
	margin: 0;
	font-family: 'Roboto', sans-serif;
}
</style>
